import React from "react";

import PageWrapper from "../components/PageWrapper";
import ContentThree from "../sections/home4/ContentOne";
import { Link } from "gatsby";

const CareerDetails = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <a href="https://developer.atlassian.com/console/install/63475127-bb3b-4694-989d-500321199a26?signature=a5287bd4d98af0060277479e8c8c15331cf51eddbd1804b1d3d3839c938f8c7c&product=jira">
                <button className="btn btn btn-red header-btn-10 rounded-10">
                Add To Jira
                </button>
              </a>
            </>
          ),
          footerStyle: "style4",
        }}
      >
        <main className="pt-23 pt-md-27 pb-13 pb-md-19 pt-lg-29 pb-lg-27">
          <ContentThree className="pb-13 pb-lg-34"/>
        </main>
      </PageWrapper>
    </>
  );
};
export default CareerDetails;
